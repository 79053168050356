<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>二级分类</template>
    </breadcrumb-nav>
    <el-card>
      <el-card class="box-card goods-add" shadow="never">
        <el-button type="text" icon="el-icon-back" @click="$router.back(-1)">返回</el-button>
        <el-divider direction="vertical"></el-divider>
        <label>父类: {{ category.name }}</label>
      </el-card>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="name" label="名称">
      </el-table-column>
      <el-table-column label="级数">二级
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
        <el-switch v-model="scope.row.status" active-text="显示" inactive-text="不显示" :active-value=1
                   :inactive-value=2 @change="changeStatus(scope.row)">
        </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作">
        <template slot-scope="{row, $index}">
          <el-row>
            <el-button icon="el-icon-top" type="primary" size="mini" @click="upindex($index,row)">上移</el-button>
            <el-button icon="el-icon-bottom" type="primary" size="mini"  @click="downindex($index,row)">下移</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[10, 20]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getCategoryChildrenRequest, SortCategoryRequest, updateCategoryStatus } from '../../../network/goods'

export default {
  name: 'SubCategory',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        categoryId: 0
      },
      list: [],
      total: 0,
      category: {}
    }
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '展示'
        case 2:
          return '不展示'
        default:
          return '未知'
      }
    }
  },
  mounted () {
    this.listQuery.categoryId = parseInt(this.$route.query.categoryId)
    this.getList()
  },
  methods: {
    getList () {
      getCategoryChildrenRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取品牌列表失败', 'error')
        }
        this.list = result.data.children
        this.total = result.data.total
        this.category = result.data.category
      })
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: upData.sortNum })
        sortItem.push({ categoryId: upData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortItem = []
        sortItem.push({ categoryId: curData.categoryId, sortNum: downData.sortNum })
        sortItem.push({ categoryId: downData.categoryId, sortNum: curData.sortNum })
        SortCategoryRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    },
    changeStatus (row) {
      updateCategoryStatus({ categoryId: row.categoryId, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改状态失败', 'error')
        }
        this.alertMessage('修改状态成功', 'success')
        this.getList()
      })
    }
  }
}
</script>

<style scoped>

</style>
